import React, {useCallback, useEffect, useState} from 'react';
import './MainView.scss';
import {TextButton} from "../Common/TextButton/TextButton";
import classNames from 'classnames';
// import {ISize} from "../../interfaces/ISize";
// import {ImageButton} from "../Common/ImageButton/ImageButton";
// import {ISocialMedia, SocialMediaData} from "../../data/info/SocialMediaData";
import {EditorFeatureData, IEditorFeature} from "../../data/info/EditorFeatureData";
// import {Tooltip} from "@material-ui/core";
// import Fade from "@material-ui/core/Fade";
// import withStyles from "@material-ui/core/styles/withStyles";
import ImagesDropZone from "./ImagesDropZone/ImagesDropZone";

const MainView: React.FC = () => {
    const [projectInProgress, setProjectInProgress] = useState(false);
    const [projectCanceled, setProjectCanceled] = useState(false);
    const [doStartEditor, setDoStartEditor] = useState(false);
    const [acceptedFileData, setAcceptedFileData] = useState([]);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const assetURL = params.get('image_url');

    const startProject = useCallback(() => {
        fetchAndCovertToBlob(assetURL);
    }, []);

    useEffect(() => {
        startProject();
    }, [startProject])
    

    const endProject = () => {
        setProjectInProgress(false);
        setProjectCanceled(true);
    };

    const getClassName = () => {
        return classNames(
            "MainView", {
                "InProgress": projectInProgress,
                "Canceled": !projectInProgress && projectCanceled
            }
        );
    };

    // const DarkTooltip = withStyles(theme => ({
    //     tooltip: {
    //         backgroundColor: "#171717",
    //         color: "#ffffff",
    //         boxShadow: theme.shadows[1],
    //         fontSize: 11,
    //         maxWidth: 120
    //     },
    // }))(Tooltip);

    // const getSocialMediaButtons = (size:ISize) => {
    //     return SocialMediaData.map((data:ISocialMedia, index: number) => {
    //         return <DarkTooltip
    //             key={index}
    //             disableFocusListener
    //             title={data.tooltipMessage}
    //             TransitionComponent={Fade}
    //             TransitionProps={{ timeout: 600 }}
    //             placement="left"
    //         >
    //             <div>
    //                 <ImageButton
    //                     buttonSize={size}
    //                     image={data.imageSrc}
    //                     imageAlt={data.imageAlt}
    //                     href={data.href}
    //                 />
    //             </div>
    //         </DarkTooltip>
    //     });
    // };

    const getEditorFeatureTiles = () => {
        return EditorFeatureData.map((data:IEditorFeature) => {
            return <div
                className="EditorFeaturesTiles"
                key={data.displayText}
            >
                <div
                    className="EditorFeaturesTilesWrapper"
                >
                    <img
                        draggable={false}
                        alt={data.imageAlt}
                        src={data.imageSrc}
                    />
                    <div className="EditorFeatureLabel">
                        {data.displayText}
                    </div>
                </div>
            </div>
        });
    };

    const fetchAndCovertToBlob = (url)=> {
        fetch(url)
            .then( async response => { 
                const contentType = response.headers.get('content-type');
                return { blob: await response.blob(), contentType }
            })
            .then( blobData => {
                const { blob, contentType } = blobData;
                const file = new File([blob], 'annotation', {type: contentType})
                setAcceptedFileData([file]);
                
                setProjectInProgress(true);
                setDoStartEditor(true);
            })
    }

    if (!assetURL) {
        return (<div style={{'textAlign': 'center', padding: '5rem'}}>Image URL required.</div>);
    }

    return (
        <div className={getClassName()}>
            <div className="Slider" id="lower">
                <div className="TriangleVertical">
                    <div className="TriangleVerticalContent"/>
                </div>
            </div>

            <div className="Slider" id="upper">
                <div className="TriangleVertical">
                    <div className="TriangleVerticalContent"/>
                </div>
            </div>

            <div className="LeftColumn">
                <div className={"LogoWrapper"}>
                    <img
                        draggable={false}
                        alt={"main-logo"}
                        src={"https://dsphere.network/assets/svg/logo-whie.svg"}
                    />
                </div>
                <div className="EditorFeaturesWrapper">
                    {/* {getEditorFeatureTiles()} */}
                    <div style={{ 'color': '#ffffff', fontSize: '20px', textAlign: 'center', width: '100%' }}>Loading data...</div>
                </div>
                <div className="TriangleVertical">
                    <div className="TriangleVerticalContent"/>
                </div>
                {/* {projectInProgress && <TextButton
                    label={"Go Back"}
                    onClick={endProject}
                />} */}
            </div>
            <div className="RightColumn">
                <div/>
                <ImagesDropZone acceptedFileData={acceptedFileData} doStartEditor={doStartEditor} />
                {/* <div className="SocialMediaWrapper">
                    {getSocialMediaButtons({width: 30, height: 30})}
                </div> */}
                {/* {!projectInProgress && <TextButton
                    label={"Get Started"}
                    onClick={startProject}
                />} */}
            </div>
        </div>
    );
};

export default MainView;